@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap');

@font-face {
  font-family: "HK Grotesk";
  font-style: normal;
  font-weight: 500;
  src: url("../fonts/HKGrotesk-Medium.ttf");
}

body {
  margin: 0;
  padding: 0;
  position: relative;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(180deg, #1463D4 0%, #049ACE 100%);
}

.form-block {
  width: 528px;
  min-height: 387px;
  background: #FFFFFF;
  box-shadow: 0 2px 15px rgba(115, 161, 172, 0.3);
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: flex-start;
  padding: 40px;
  box-sizing: border-box;
}

.form-block h2 {
  font-family: 'HK Grotesk', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 31px;

  color: #151A24;
}

.logo-blog img {
  height: 49px;
}

.form-block #button {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 448px;
  height: 50px;
  margin-top: 20px;
  background: #0E78D1;
  padding: 0;
  border-radius: 5px;
  border: 0;
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  color: #FFFFFF;
  cursor: pointer;
  outline: none;
}

.form-block #button:hover {
  background-color: #0066ba;
  border-color: transparent;
  color: #fff;
}

.form-block #button:focus {
  box-shadow: 0 0 0 0.125em rgba(72, 95, 199, .25);
}

.is-loading {
  color: transparent !important;
  pointer-events: none;
  display: inline-block;
}

.is-loading::after {
  content: " ";
  display: block;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: 3px solid #fff;
  border-color: #fff transparent #fff transparent;
  box-sizing: border-box;
  animation: spinAround 1.2s linear infinite;
  position: absolute;
  left: calc(50% - (30px * .5));
}

@keyframes spinAround {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.disabled {
  opacity: .5;
}

.input-block {
  font-family: 'HK Grotesk', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
}

.input-block .label {
  color: #616774;
}

.input-block #input {
  margin: 5px 0;
  width: 448px;
  height: 50px;
  border: 1px solid #C8CBD1;
  box-sizing: border-box;
  border-radius: 5px;
  color: #151A24;
  outline: none;
  padding-left: 15px;
}

.input-block #input.error {
  border: 1px solid #FF2D55;
}

#error-text {
  margin: 0;
  margin-top: -5px;
  height: 21px;
  color: #FF2D55;
}

@media (max-width: 550px) {
  .form-block {
    width: 90%;
    padding: 40px;
  }

  .input-block, .form-block #button {
    width: 100%;
  }

  .input-block #input {
    width: 100%;
  }
}

@media (max-width: 350px) {
  .form-block h2 {
    font-size: 21px;
  }
}

.notie-container {
  position: absolute;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'Roboto', sans-serif;
  text-align: center;
  width: 100%;
  min-height: 70px;
  padding: 10px;
  font-size: 21px;
  color: #ffffff;
  box-shadow: none;
  transition: all 0.5s ease 0s;
  box-sizing: border-box;
}

.notie-background-warning {
  background: #e15b5b;
}

.notie-background-success {
  background: #57BF57;
}
